<template>
  <div class="containers">
    <Header mode="normal">
      <!-- <template v-slot:userButton
        ><UserButton class="header-userButton d-none d-lg-block"
      /></template> -->
      <template v-slot:headerToggler><HeaderToggler /></template>
      <template v-slot:avater><Avatar /></template>
    </Header>
    <div class="wrapper">
      <MainProgress />
      <Sidebar />
      <transition name="main-fade">
        <router-view class="main" :key="$route.path"></router-view>
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'
import Footer from './Footer.vue'
import MainProgress from '../components/MainProgress.vue'

import HeaderToggler from '../components/HeaderToggler'
import Avatar from '../components/Avatar.vue'

export default {
  name: 'Containers',
  components: {
    Header,
    Sidebar,
    Footer,
    MainProgress,
    HeaderToggler,
    Avatar
  }
}
</script>
