<template>
  <div style="flex:1;padding:5px;">
    <hr style="margin:30px 0;" />
    <div class="accordion" role="tablist">
      <b-button block v-b-toggle.test-link variant="primary">
        TEST LINK
      </b-button>
      <b-collapse id="test-link" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <!-- CONNECTION -->
          <router-link
            class="sidebar-item"
            to="/connections/be51bbf0-e1c1-4732-8696-d6c183956c20/view"
          >
            <div class="sidebar-icon">
              <span class="material-icons">swap_horiz</span>
            </div>
            <div class="sidebar-item-text">Connections view</div>
          </router-link>
          <router-link
            class="sidebar-item"
            to="/connections/be51bbf0-e1c1-4732-8696-d6c183956c20/edit"
          >
            <div class="sidebar-icon">
              <span class="material-icons">swap_horiz</span>
            </div>
            <div class="sidebar-item-text">Connections edit</div>
          </router-link>

          <router-link class="sidebar-item" to="/connections/new">
            <div class="sidebar-icon">
              <span class="material-icons">swap_horiz</span>
            </div>
            <div class="sidebar-item-text">Connections new</div>
          </router-link>
          <hr />
          <!-- SUBSCRIPTION -->
          <router-link
            class="sidebar-item"
            to="/platform/subscription/38975286-ff53-4033-ae35-85583734cba9/view"
            ><div class="sidebar-icon">
              <span class="material-icons">label</span>
            </div>
            <div class="sidebar-item-text">Subscription view</div>
          </router-link>
          <router-link
            class="sidebar-item"
            to="/platform/subscription/38975286-ff53-4033-ae35-85583734cba9/edit"
            ><div class="sidebar-icon">
              <span class="material-icons">label</span>
            </div>
            <div class="sidebar-item-text">Subscription edit</div>
          </router-link>
          <router-link class="sidebar-item" to="/platform/subscription/new"
            ><div class="sidebar-icon">
              <span class="material-icons">label</span>
            </div>
            <div class="sidebar-item-text">Subscription new</div>
          </router-link>
          <hr />
          <!-- USER -->
          <router-link
            class="sidebar-item"
            to="/platform/user/d7839431-b5c4-442a-852f-d0428b31a6b6/edit"
            ><div class="sidebar-icon">
              <span class="material-icons">group</span>
            </div>
            <div class="sidebar-item-text">user edit</div>
          </router-link>
          <router-link class="sidebar-item" to="/platform/user/new"
            ><div class="sidebar-icon">
              <span class="material-icons">group</span>
            </div>
            <div class="sidebar-item-text">user new</div>
          </router-link>

          <hr />
          <!-- USER INFO -->
          <router-link class="sidebar-item" to="/user-info">
            <div class="sidebar-icon">
              <span class="material-icons">info</span>
            </div>
            <div class="sidebar-item-text">User Info</div>
          </router-link>

          <router-link class="sidebar-item" to="/user-info/edit">
            <div class="sidebar-icon">
              <span class="material-icons">info</span>
            </div>
            <div class="sidebar-item-text">User Info edit</div>
          </router-link>

          <hr />
          <!-- PAGES -->
          <router-link class="sidebar-item" to="/pages/login">
            <div class="sidebar-icon">
              <span class="material-icons">person</span>
            </div>
            <div class="sidebar-item-text">Login</div>
          </router-link>

          <router-link class="sidebar-item" to="/pages/page404">
            <div class="sidebar-icon">
              <span class="material-icons">error_outline</span>
            </div>
            <div class="sidebar-item-text">404</div>
          </router-link>
        </b-card-body>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarTestLink'
}
</script>
