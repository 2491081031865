<template>
  <div class="avatar">
    <b-dropdown
      id="dropdown-avatar"
      right
      class="avatar-dropdown"
      variant="avatar"
      menu-class="avatar-menu"
    >
      <template #button-content>
        <b-avatar variant="primary" size="35px" :text="avatarText"></b-avatar>
      </template>
      <b-dropdown-header id="dropdown-header-label">
        <div class="avatar-userInfo">
          <div class="avatar-userInfo-text avatar-userInfo-name">
            {{ userSelf.firstName }} {{ userSelf.lastName }}
            <span class="avatar-userInfo-tips"> {{ userSelf.email }}</span>
          </div>
        </div>
      </b-dropdown-header>

      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item
        target="_blank"
        href="https://assets.bonder.fivestones.net/user-manual.pdf"
      >
        <span class="dropdown-item-icon material-icons-outlined"
          >description</span
        >
        <div class="dropdown-item-text">User Manual</div>
      </b-dropdown-item>
      <b-dropdown-item :to="`/pages/password/setup`">
        <span class="dropdown-item-icon material-icons">lock</span>
        <div class="dropdown-item-text">Reset Password</div>
      </b-dropdown-item>
      <b-dropdown-item @click="logout">
        <span class="dropdown-item-icon material-icons">logout</span>
        <div class="dropdown-item-text">Logout</div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Avatar',
  computed: {
    ...mapState({
      userSelf: state => state.user.self
    }),
    avatarText() {
      if (!this.userSelf.firstName && !this.userSelf.lastName) return 'null'
      let firstLetter = this.userSelf.firstName.substr(0, 1)
      let lastLetter = this.userSelf.lastName.substr(0, 1)
      return firstLetter + lastLetter
    }
  },
  methods: {
    async logout() {
      let status = await this.$store.dispatch('logout')
      if (status === 200) {
        return this.$router.push({
          path: '/pages/login',
          query: { status: 'logout' }
        })
      } else {
        return this.$router.push('/pages/page404')
      }
    }
  }
}
</script>
