<template>
  <div class="header-menu">
    <div class="d-none d-lg-block" @click="toggleSidebarDesktop">
      <span class="material-icons">menu</span>
    </div>
    <div class="d-lg-none" @click="toggleSidebarMobile">
      <span class="material-icons">menu</span>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  name: 'HeaderToggler',
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
    ...mapMutations(['toggleSidebarDesktop', 'toggleSidebarMobile'])
  }
}
</script>
