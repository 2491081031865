<template>
  <div :class="sidebarClasses" @click="closeSidebar">
    <ul class="sidebar-nav">
      <li>
        <router-link class="sidebar-item" to="/connections">
          <div class="sidebar-icon">
            <span class="material-icons">swap_horiz</span>
          </div>
          <div class="sidebar-item-text">Reporting Connections</div>
        </router-link>
      </li>

      <li>
        <router-link class="sidebar-item" to="/account-auth/facebook">
          <div class="sidebar-icon">
            <span class="material-icons">account_circle</span>
          </div>
          <div class="sidebar-item-text">Facebook Account Authorisation</div>
        </router-link>
      </li>

      <li>
        <router-link class="sidebar-item" to="/account-auth/google">
          <div class="sidebar-icon">
            <span class="material-icons">account_circle</span>
          </div>
          <div class="sidebar-item-text">Google Account Authorisation</div>
        </router-link>
      </li>

      <li v-if="userRole === 'CLIENT'">
        <router-link class="sidebar-item" to="/user-info">
          <div class="sidebar-icon">
            <span class="material-icons">info</span>
          </div>
          <div class="sidebar-item-text">User Info</div>
        </router-link>
      </li>

      <li v-if="userRole !== 'CLIENT'">
        <router-link class="sidebar-item" to="/history">
          <div class="sidebar-icon">
            <span class="material-icons">history</span>
          </div>
          <div class="sidebar-item-text">Account Change Log</div>
        </router-link>
      </li>

      <li class="platform" v-if="userRole !== 'CLIENT'">
        <div
          v-b-toggle.collapse-platform
          class="sidebar-item collapse-btn"
          @click="collapseBtnClick"
        >
          <div class="sidebar-icon">
            <span class="material-icons">manage_accounts</span>
          </div>
          Platform Management
          <div class="collapse-arrow">
            <span class="material-icons">keyboard_arrow_right</span>
          </div>
        </div>
        <b-collapse visible class="collapse-content" id="collapse-platform">
          <router-link class="sidebar-item" to="/platform/subscription">
            <div class="sidebar-icon">
              <span class="material-icons">label</span>
            </div>
            <div class="sidebar-item-text">Subscription</div>
          </router-link>
          <router-link class="sidebar-item" to="/platform/user">
            <div class="sidebar-icon">
              <span class="material-icons">group</span>
            </div>
            <div class="sidebar-item-text">User</div>
          </router-link>
        </b-collapse>
      </li>
    </ul>
    <SidebarTestLink v-if="env" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SidebarTestLink from './SidebarTestLink'
export default {
  name: 'Sidebar',
  components: { SidebarTestLink },
  data() {
    return {
      env: process.env.NODE_ENV === 'development',
      open: this.sidebarShow
    }
  },
  watch: {
    sidebarShow(val) {
      this.open = val
    },
    open: {
      immediate: true,
      handler(val) {
        val === true ? this.createBackdrop() : this.removeBackdrop()
      }
    }
  },
  computed: {
    ...mapState({
      userSelf: state => state.user.self,
      sidebarShow: state => state.sidebarShow
    }),
    sidebarClasses() {
      const haveResponsiveClass = this.sidebarShow === 'responsive'
      return [
        'sidebar',
        {
          'sidebar-show': this.sidebarShow === true,
          'sidebar-lg-show': haveResponsiveClass
        }
      ]
    },
    userRole() {
      return this.userSelf ? this.userSelf.role : false
    }
  },
  methods: {
    closeSidebar() {
      // console.log('close sidebar')
      this.open = 'responsive'
      this.$store.commit('setSidebarShow', this.open)
    },
    collapseBtnClick(e) {
      e.stopPropagation()
    },
    createBackdrop() {
      // console.log('create backdrop')
      const backdrop = document.createElement('div')
      const wrapper = document.querySelector('.wrapper')
      backdrop.addEventListener('click', this.closeSidebar)

      backdrop.className = 'sidebar-backdrop'
      backdrop.id = 'sidebar-backdrop'
      wrapper.appendChild(backdrop)
    },
    removeBackdrop() {
      // console.log('remove backdrop')
      const backdrop = document.getElementById('sidebar-backdrop')
      const wrapper = document.querySelector('.wrapper')
      if (backdrop) {
        backdrop.removeEventListener('click', this.closeSidebar)
        wrapper.removeChild(backdrop)
      }
    }
  }
}
</script>
